import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileClientService {

  configService: ConfigService;

  constructor(private httpClient: HttpClient) {
    this.configService = new ConfigService();
  }


  profileClient(id) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {
      'userID': id
    };
    return this.httpClient.post(this.configService.serverIp.concat('/profileClient'), data, { headers });
  }

  updateInfoUser(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/updateInfoUser'), data, { headers });
  }

  changeUserState(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/changeUserState'), data, { headers });
  }

  profileClientPackage(id) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {
      'userID': id
    };
    return this.httpClient.post(this.configService.serverIp.concat('/profileClientPackage'), data, { headers });
  }

  profileClientPrealert(id) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {
      'userID': id
    };
    return this.httpClient.post(this.configService.serverIp.concat('/profileClientPrealert'), data, { headers });
  }

  userHasPermission(url) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {
      'validateURL': url
    };
    return this.httpClient.post(this.configService.serverIp.concat('/userHasPermission'), data, { headers });
  }

  addPackagesContentsToGuide(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/addPackagesContentsToGuide'), data, { headers });
  }

  removePackageFromGuide(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/removePackageFromGuide'), data, { headers });
  }

  updateInfoPrealert(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/updateInfoPrealert'), data, { headers });
  }
}
